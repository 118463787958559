const config = {
    "DISPLAY_TIME_CALIBRATION_TEST_POINT": 10000,
    "DISPLAY_TIME_CALIBRATION_POINT": 4000,
    "DISPLAY_TIME_CALIBRATION_INSTRUCTIONS": 10000,
    "DISPLAY_TIME_PERFORMANCE_TEST_INSTRUCTIONS": 10000,
    "NUM_TEST_POINTS": 10,
    "SERVER_URL": 'http://127.0.0.1:5000',
    "VIDEO_URL": "https://brain-gaze-video-bucket.s3.us-east-2.amazonaws.com/eagle-stock-video.mp4",
    "API_NAME": "braingazeAPI"
}

export default config