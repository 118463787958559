import React, { Component } from 'react';
import { marked } from 'marked';
import webgazer from 'webgazer'; // Import WebGazer
import { getSessionId } from '../../util/UserSession';
import { sendMediaViewData } from '../../api/requests';
import config from '../../config';

class MediaViewPage extends Component {
  constructor(props) {
    super(props);

    this.gazeCollectionInterval = null; // Initialize gaze data collection interval

    const savedStateJSON = sessionStorage.getItem('mediaViewComponentState');

    const initialState = {
      sessionId: getSessionId(),
      markdown: '',
      videoUrl: config["VIDEO_URL"],
      isVideoPlaying: false, // Track video playing state
      isVideoEnded: false,
      videoEndTime: -1,
      gazeCollectionResolution: 100,
      webgazerInitialized: props.webgazerInitialized,
      windowInnerHeight: null,
      windowInnerWidth: null,
      recordedGazeLocations: [],
      recordedVideoTimestamps: []
    };


    if (savedStateJSON) {
      const savedState = JSON.parse(savedStateJSON);
      if (savedState.isVideoEnded) {
        this.state = savedState;
        return;
      }
    }

    this.state = initialState;
  }

  setupWebgazer() {

    // TODO : Need to double check that webgazer is still calibrated from the calibration step
    /* Don't show subject video, could be distracting */
    webgazer.showVideo(false);
    /* Don't show prediction points, could also be distracting */
    webgazer.showPredictionPoints(false);
    /* No other state estimation required */
    webgazer.applyKalmanFilter(false);

    /* In case we are loading in directly to media without going thru calibration */
    webgazer.clearGazeListener();
    // webgazer.resume();
  }

  componentWillUnmount() {
    sendMediaViewData(this.state.sessionId, 'media', this.state);
  }

  componentDidMount() {
    // Load markdown content and initialize WebGazer
    fetch('/markdown/media-view-instructions.md')
      .then((response) => response.text())
      .then((text) => this.setState({ markdown: marked.parse(text) }))
      .catch((error) => console.error('Error loading markdown:', error));

    this.setupWebgazer()
  }

  // Event handler for video play
  handleVideoPlay = async () => {
    this.setState({ isVideoPlaying: true });
    webgazer.resume();

    // Start collecting gaze data at 100ms intervals when the video is playing
    this.gazeCollectionInterval = setInterval(async () => {
      if (this.state.isVideoPlaying) {
        const recordedGazeLocations = [...this.state.recordedGazeLocations];
        const recordedVideoTimestamps = [...this.state.recordedVideoTimestamps]

        try {
          // Simulate getting the predicted gaze location
          const predictedGazeLocation = await webgazer.getCurrentPrediction();
          const videoTimestamp = this.videoElement.currentTime;

          recordedGazeLocations.push([parseInt(predictedGazeLocation.x), parseInt(predictedGazeLocation.y)]);
          recordedVideoTimestamps.push(videoTimestamp);

          this.setState({
            recordedGazeLocations,
            recordedVideoTimestamps
          });

        } catch (error) {
          console.error("Error fetching predicted gaze location: ", error)
        }
      }
    }, this.state.gazeCollectionResolution);
  };

  // Event handler for video pause
  handleVideoPause = () => {

    this.setState({ isVideoPlaying: false });
    webgazer.pause(); // Save resources

    // Stop collecting gaze data when the video is paused
    clearInterval(this.gazeCollectionInterval);
  };

  handleVideoEnd = () => {
    const videoElement = document.getElementById('video-player')
    const containerElement = document.getElementById('video-container');
  
    const videoOffsetHeight = videoElement ? videoElement.getBoundingClientRect().top : null;
    const videoOffsetWidth = videoElement ? videoElement.getBoundingClientRect().left : null;
    const containerHeight = containerElement ? containerElement.clientHeight : null;
    const containerWidth = containerElement ? containerElement.clientWidth : null;
  
    this.setState({
      isVideoEnded: true,
      isVideoPlaying: false,
      videoEndTime: new Date().getTime(),
      windowInnerHeight: window.innerHeight,
      windowInnerWidth: window.innerWidth,
      videoOffsetHeight: videoOffsetHeight,
      videoOffsetWidth: videoOffsetWidth,
      containerHeight: containerHeight,
      containerWidth: containerWidth
    }, () => {
      sessionStorage.setItem('mediaViewComponentState', JSON.stringify(this.state));
      sendMediaViewData(this.state.sessionId, 'media', this.state);
    });
    webgazer.pause();
  };

  renderResultsTable() {
    const { recordedVideoTimestamps, recordedGazeLocations } = this.state;

    if (recordedVideoTimestamps.length === 0 || recordedGazeLocations.length === 0) {
      return null; // Return null if there's no data
    }

    return (
      <table className='gazeTable'>
        <thead className='gazeTableHead'>
          <tr className='gazeTableTr'>
            <th className='gazeTableTh'>Timestamp</th>
            <th className='gazeTableTh'>Recorded Gaze Location (x, y)</th>
          </tr>
        </thead>
        <tbody>
          {recordedVideoTimestamps.map((timestamp, index) => (
            <tr className='gazeTableTr' key={index}>
              <td className='gazeTableTd'>{timestamp.toFixed(2)}s</td>
              <td className='gazeTableTd'>({recordedGazeLocations[index][0]}, {recordedGazeLocations[index][1]})</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render_video_container() {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: this.state.markdown }} />
        <div className='video-container' id="video-container">
          <video
            id="video-player"
            ref={(el) => (this.videoElement = el)} // Store a reference to the video element
            controls
            className='react-player'
            onPlay={this.handleVideoPlay}
            onPause={this.handleVideoPause}
            onEnded={this.handleVideoEnd}
          >
            <source src={this.state.videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {this.state.isVideoEnded && this.renderResultsTable()} {/* Conditional rendering */}
      </div>
    );
  }

  render() {
    return this.render_video_container();
  }
}

export default MediaViewPage;
